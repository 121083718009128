import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	ThemeProvider,
	TextField,
	Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { getFeriados, getFeriado } from "../../../services/FeriadoService.js";
import FeriadoDialog from "./FeriadoDialog.jsx";
import Message from "../../../utils/Message.jsx";
import { Box } from "@mui/system";
import React from 'react';



const initialState = {
	descripcion: "",
	fechaDesde: null,
	fechaHasta: null,
	orden: "ASC",
	ordenarPor: "fecha",
	estado: "todo",
	rows: 0,
	first: 0,
};

const estadoOptions = [
	{
		id: "todo",
		descripcion: "Todo",
	},
	{
		id: "habilitado",
		descripcion: "Habilitado",
	},
	{
		id: "deshabilitado",
		descripcion: "Deshabilitado",
	},
];

const ordenOptions = [
	{
		id: "ASC",
		descripcion: "Ascendente",
	},
	{
		id: "DESC",
		descripcion: "Descendente",
	},
];

const ordenarPorOptions = [
	{
		id: "descripcion",
		descripcion: "Descripción",
	},
	{
		id: "fecha",
		descripcion: "Fecha",
	},
];

const nameItemsText = "Feriados";
//
// ##################################################################################################################### 
//
const FeriadoTable = ({ ...data }) => {
	const [searchData, setSearchData] = useState(initialState);
	const [itemsCrud, setItemsCrud] = useState([]);
	const [itemCrud, setItemCrud] = useState({});
	const [actionCrud, setActionCrud] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalRowsCount, setTotalRowsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [message, setMessage] = useState({});
	const [showMessage, setShowMessage] = useState(false);
	const [feriados, setFeriados] = useState([]);
	const [isDoingSomething, setIsDoingSomething] = useState(false);




	const tableColumns = [
		{
			name: "id",
			label: "ID",
			options: {
				display: "excluded",
			},
		},
		{
			name: "descripcion",
			label: "Descripción",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "fecha",
			label: "Fecha",
			options: {
				filter: false,
				sort: false,
				customBodyRenderLite: (dataIndex, rowIndex) => {
					return new Date(itemsCrud[dataIndex].fecha).toLocaleDateString("es-AR", { year: 'numeric', month: '2-digit', day: '2-digit' });
				}

			},
		},
		{
			name: "actions",
			label: "Acciones",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex, rowIndex) => (
					<>
						{itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Editar">
								<IconButton
									size="small"
									aria-label="update"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "update")}
								>
									<EditOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}

						{itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Deshabilitar">
								<IconButton
									size="small"
									aria-label="disable"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "disable")}
								>
									<RemoveIcon />
								</IconButton>
							</Tooltip>
						)}

						{!itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Habilitar">
								<IconButton
									size="small"
									aria-label="enable"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "enable")}
								>
									<AddIcon />
								</IconButton>
							</Tooltip>
						)}

						{(
							<Tooltip title="Ver">
								<IconButton
									size="small"
									aria-label="read"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "read")}
								>
									<ZoomInIcon />
								</IconButton>
							</Tooltip>
						)}
					</>
				),
			},
		},
	];

	const tableOptions = {
		storageKey: "my-table-state",
		rowsPerPageOptions: [10, 20, 30],
		serverSide: true,
		count: totalRowsCount,
		rowsPerPage: rowsPerPage,
		onChangeRowsPerPage: (numberOfRows) => {
			setRowsPerPage(numberOfRows);
		},
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage);
		},
		download: false,
		filter: false,
		print: false,
		search: false,
		viewColumns: false,
		selectableRowsHeader: false,
		selectableRows: "none",
		elevation: 1,
		textLabels: {
			body: {
				noMatch: "No se han encontrado resultados para esta búsqueda",
			},
			pagination: {
				next: "Próxima",
				previous: "Previa",
				rowsPerPage: "Filas:",
				displayRows: "de",
			},
		},
	};
	//
	// ################################################################################
	//
	const load = async () => {
		searchData.rows = rowsPerPage;
		searchData.first = currentPage * rowsPerPage;
		searchData.sortField = searchData.ordenarPor;
		searchData.sortOrder = searchData.orden;
		setIsDoingSomething(true);
		const response = await getFeriados(searchData);
		setIsDoingSomething(false);

		if (response.ok) {
			setItemsCrud(response.data.data);
			setTotalRowsCount(response.data.count);
		} else {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

	//
	// ################################################################################
	//
	useEffect(() => {
		const loadFeriados = async () => {
			setIsDoingSomething(true);
			const response = await getFeriados({ estado: "habilitado" });
			setIsDoingSomething(false);

			if (response.ok) {
				let _response = response.data.data;
				let _feriados = [];

				_response.forEach((item) => {
					_feriados.push({
						id: item.id,
						descripcion: item.descripcion,
						label: item.descripcion,
					});
				});

				setFeriados(_feriados);
			} else {
				setFeriados([]);
				setMessage({
					messageText: response.messageText,
					severity: response.messageSeverity,
				});
				setShowMessage(true);
			}
		};
		loadFeriados();
	}, []);


	//
	// ################################################################################
	//

	useEffect(() => {
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, rowsPerPage]);

	const handleChange = (e) => {
		setSearchData({
			...searchData,
			[e.target.name]:
				e.target.type === "checkbox" ? e.target.checked : e.target.value,
		});
	};

	const handleChangeFechaDesde = (fechaDesde) => {
		setSearchData({
			...searchData,
			fechaDesde: fechaDesde,
		});
	};

	const handleChangeFechaHasta = (fechaHasta) => {
		setSearchData({
			...searchData,
			fechaHasta: fechaHasta,
		});
	};

	const search = () => {
		setCurrentPage(0);
		load();
	};

	const clean = () => {
		setSearchData(initialState);
		setItemsCrud([]);
	};

	const handleClickActionCrud = (itemCrud, action) => {
		let _itemCrud = {};

		if (action !== "create") {
			const loadItem = async () => {
				setIsDoingSomething(true);
				const response = await getFeriado({ id: itemCrud.id });
				setIsDoingSomething(false);

				if (response.ok) {

					_itemCrud = {
						id: response.data.data.id,
						descripcion: response.data.data.descripcion,
						fecha: new Date(response.data.data.fecha),
						usernameUsuarioActualiza: response.data.data.username,
						nombreUsuarioActualiza: response.data.data.nombre_usuario,
						apellidoUsuarioActualiza: response.data.data.apellido_usuario,
						fechaActualizacion: response.data.data.fecha_actualizacion,
						habilitado: response.data.data.habilitado,
					};

					setActionCrud(action);
					setItemCrud(_itemCrud);
					setOpenDialog(true);
				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();
		} else {
			setActionCrud(action);
			setItemCrud(_itemCrud);
			setOpenDialog(true);
		}
	};

	const handleDialogSave = () => {
		load();
		setOpenDialog(false);
	};

	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	//
	// ################################################################################
	//


	// let table = data.data.table

	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Paper

					elevation={2}
				>
					<Box
						sx={{
							margin: "auto",
							width: "90%",
						}}
					>
						<Box sx={{ m: 1, p: 1 }}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<h2>{nameItemsText}</h2>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<TextField
										sx={{ width: "100%", mr: 1 }}
										id="descripcion"
										name="descripcion"
										value={searchData.descripcion}
										onChange={handleChange}
										label="Descripción"
										variant="outlined"
										size="small"
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<DatePicker
										sx={{
											width: "100%",
											mb: 1,
											'& .MuiInputLabel-root': {
												transform: 'translate(14px, 9px) scale(1)',
											},
											'& .MuiInputBase-root': {
												paddingRight: '10px',
											},
											'& .MuiInputLabel-shrink': {
												transform: 'translate(14px, -8px) scale(0.75)',
											},
										}}
										id="fechaDesde"
										label="Desde"
										variant="outlined"
										name="fechaDesde"
										value={searchData.fechaDesde}
										onChange={(fechaDesde) =>
											handleChangeFechaDesde(fechaDesde)
										}
										renderInput={(params) => <TextField size="small" {...params} />}
										inputFormat="dd/MM/yyyy"
									//disabled={actionCrud === "disable" || actionCrud === "enable"}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={3} >
									<DatePicker
										sx={{
											width: "100%",
											mb: 1,
											'& .MuiInputLabel-root': {
												transform: 'translate(14px, 9px) scale(1)',
											},
											'& .MuiInputBase-root': {
												paddingRight: '10px',
											},
											'& .MuiInputLabel-shrink': {
												transform: 'translate(14px, -8px) scale(0.75)',
											},
										}}
										id="fechaHasta"
										label="Hasta"
										variant="outlined"
										name="fechaHasta"
										value={searchData.fechaHasta}
										onChange={(fechaHasta) =>
											handleChangeFechaHasta(fechaHasta)
										}
										renderInput={(params) => <TextField size="small" {...params} />}
										inputFormat="dd/MM/yyyy"

									//disabled={actionCrud === "disable" || actionCrud === "enable"}
									/>
								</Grid>

								<Grid item xs={12} md={6} lg={3}>
									<FormControl sx={{ width: "100%" }}>
										<InputLabel id="estado-select-label">Estado</InputLabel>
										<Select
											sx={{ width: "100%", mr: 1 }}
											labelId="estado"
											label="Estado"
											id="estado"
											name="estado"
											value={searchData?.estado || ""}
											size="small"
											onChange={handleChange}
										>
											{estadoOptions.map((item) => (
												<MenuItem key={item.id} value={item.id}>
													{item.descripcion}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>

							<Grid container spacing={1}>
							</Grid>
						</Box>

						<Box sx={{ m: 1, p: 1 }}>
							<Grid container spacing={1}>
								<Grid item xs={12} md={3}>
									<FormControl sx={{ width: "100%" }}>
										<InputLabel id="ordenarPor-select-label">
											Ordenar por:
										</InputLabel>
										<Select
											sx={{ width: "100%", mr: 1 }}
											labelId="ordenarPor"
											label="Ordenar por:"
											id="ordenaPor"
											name="ordenarPor"
											value={searchData.ordenarPor}
											size="small"
											onChange={handleChange}
										>
											{ordenarPorOptions.map((item) => (
												<MenuItem key={item.id} value={item.id}>
													{item.descripcion}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={0.5}>
								</Grid>
								<Grid item xs={12} md={4}>
									<Grid container spacing={1}>
										<Grid item xs={6}>
											<FormControl component="fieldset">
												<FormLabel
													component="legend"
													sx={{
														textAlign: "center",
														mb: 1,
														fontSize: "0.875rem",
													}}
												>
													Orden
												</FormLabel>
												<RadioGroup
													aria-label="Orden"
													name="orden"
													value={searchData.orden}
													onChange={handleChange}
													row
												>
													{ordenOptions
														.slice(0, Math.ceil(ordenOptions.length / 2))
														.map((item) => (
															<FormControlLabel
																key={item.id}
																value={item.id}
																label={item.descripcion}
																control={<Radio />}
															/>
														))}
												</RadioGroup>
											</FormControl>
										</Grid>
										<Grid item xs={6}>
											<FormControl component="fieldset">
												<FormLabel
													component="legend"
													sx={{
														textAlign: "center",
														mb: 1,
														fontSize: "0.875rem",
													}}
												>
													&nbsp;
												</FormLabel>
												<RadioGroup
													aria-label="Orden"
													name="orden"
													value={searchData.orden}
													onChange={handleChange}
													row
												>
													{ordenOptions
														.slice(Math.ceil(ordenOptions.length / 2))
														.map((item) => (
															<FormControlLabel
																key={item.id}
																value={item.id}
																label={item.descripcion}
																control={<Radio />}
															/>
														))}
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
						<Box sx={{ m: 1, p: 1 }}>
							<Grid container spacing={1}>
								<Grid item xs={12} md={3} lg={2}>
									<Button
										sx={{ width: "100%" }}
										variant="contained"
										onClick={() => handleClickActionCrud({}, "create")}
										startIcon={<AddIcon />}
									>
										Nuevo
									</Button>
								</Grid>
								<Grid item xs={12} md={3} lg={2}>
									<Button
										sx={{ width: "100%" }}
										variant="contained"
										onClick={search}
										startIcon={<SearchIcon />}
									>
										Buscar
									</Button>
								</Grid>
								<Grid item xs={12} md={3} lg={2}>
									<Button
										sx={{ width: "100%", mr: 1 }}
										variant="contained"
										onClick={clean}
										startIcon={<CleaningServicesIcon />}
									>
										Limpiar
									</Button>
								</Grid>
								<Grid item xs={12} md={3} lg={2} >
								</Grid>
								<Grid item xs={12} md={3} lg={2} >
								</Grid>
							</Grid>
						</Box>

						<Box sx={{ m: 1, p: 1 }}>
						</Box>
						{isDoingSomething && <LinearProgress />}

						<MUIDataTable columns={tableColumns} data={itemsCrud} options={tableOptions} />

					</Box>
				</Paper>

				{/* >>>> Dialog */}

				{openDialog && (
					<FeriadoDialog
						feriados={feriados}
						onSave={handleDialogSave}
						onClose={handleDialogClose}
						itemCrud={itemCrud}
						actionCrud={actionCrud}
						setMessage={setMessage}
						setShowMessage={setShowMessage}
					/>
				)}

				{/* >>>> Messages */}

				{showMessage && (
					<Message
						showMessage={showMessage}
						setShowMessage={setShowMessage}
						message={message}
					/>
				)}
			</LocalizationProvider>
		</>
	);
};

export default FeriadoTable;
