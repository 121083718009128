import { urlBackendUsuarios } from "../core/configs/config";
import "../utils/axios.interceptor";
import HttpService from '../services/HttpService';
HttpService.configure();

const axiosClient = HttpService.getAxiosClient();


const epName = "/fuero-subtipo-act-juzgado/";
const nameItemsText = "Fueros Subtipos Actuaciones Juzgados";
const nameItemText = "Fuero Subtipo Actuación Juzgado";
const itemGender = "MF";
const elLa = itemGender === "M" ? "el" : "la";
const unUna = itemGender === "M" ? "un" : "una";
//
// #############################################################################
//
export const getFuerosSubtiposActJuzgado = async (params) => {
	let _params = {};
	let _errorMessage = `Se produjo un error al obtener ${nameItemsText}`;

	if (params.idFuero) {
		_params.id_fuero = params.idFuero;
	}

	if (params.idSubtipoActJuzgado) {
		_params.id_subtipo_act_juzgado = params.idSubtipoActJuzgado;
	}

	if (params.idTipoActJuzgado) {
		_params.id_tipo_act_juzgado = params.idTipoActJuzgado;
	}

	if (params.firmas) {
		if (params.firmas === "ambas") {
			_params.firma_digital = true;
			_params.firma_electronica = true;
		}

		else if (params.firmas === "digital") {
			_params.firma_digital = true;
		}
		else if (params.firmas === "electronica") {
			_params.firma_electronica = true;
		}
		else if (params.firmas === "ninguna") {
			_params.firma_digital = false;
			_params.firma_electronica = false;
		}
	}

	if (params.visibilidad) {
		_params.visibilidad = params.visibilidad;
	}

	if (params.estado) {
		let estado =
			params.estado === "habilitado"
				? "H"
				: params.estado === "deshabilitado"
					? "D"
					: "";
		_params.habilitado = estado;
	}

	if (params.rows) {
		_params.rows = params.rows;
	}

	if (params.first) {
		_params.first = params.first;
	}

	if (params.sortField) {
		_params.sortfield = params.sortField;
	}

	if (params.sortOrder) {
		_params.sortorder = params.sortOrder;
	}

	try {
		const response = await axiosClient.get(`${urlBackendUsuarios}${epName}`, {
			params: _params,
			headers: {
				"Access-Control-Allow-Origin": "*",
			},
		});

		if (response.status === 200) {
			let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				return response;
			}
		}

		// si hay algun error controlado

		console.log(_errorMessage);
		console.log(response);

		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	}
};
//
// #############################################################################
//
export const getFueroSubtipoActJuzgado = async (params) => {
	let _errorMessage = `Se produjo un error al obtener ${elLa} ${nameItemText}`;

	try {
		const response = await axiosClient.get(`${urlBackendUsuarios}${epName}`, {
			params: params,
			headers: {
				"Access-Control-Allow-Origin": "*",
			},
		});

		if (response.status === 200) {
			let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				return response;
			}
		}

		// si hay algun error controlado

		console.log(_errorMessage);
		console.log(response);

		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	}
};
//
// #############################################################################
//
export const saveFueroSubtipoActJuzgado = async (actionCrud, item) => {
	let _errorMessage = `No fue posible guardar ${elLa} ${nameItemText}`;

	let _item = {
		id_fuero: item.idFuero,
		id_subtipo_act_juzgado: item.idSubtipoActJuzgado,
		firma_digital: item.firmaDigital,
		firma_electronica: item.firmaElectronica,
		visibilidad: item.visibilidad,
		funcion_list: item.listFunciones,
		username_actualiza: item.usernameUsuarioActualiza,
	};
	if (actionCrud !== "create") {
		_item.id = item.id;
	}

	try {
		let response = {};
		if (actionCrud === "create") {
			response = await axiosClient.post(`${urlBackendUsuarios}${epName}`, _item, {
				headers: { "Access-Control-Allow-Origin": "*" },
			});
		} else if (actionCrud === "update") {
			response = await axiosClient.put(`${urlBackendUsuarios}${epName}`, _item, {
				headers: { "Access-Control-Allow-Origin": "*" },
			});
		} else if (actionCrud === "disable" || actionCrud === "enable") {

			response = await axiosClient.delete(`${urlBackendUsuarios}${epName}`, {
				params: {
					id: _item.id,
					username_actualiza: _item.username_actualiza,
					action: actionCrud,
				},
				headers: { "Access-Control-Allow-Origin": "*" },
			});
		}

		if (response.status === 200) {
			let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				response.messageText =
					`${nameItemText}` +
					(actionCrud === "disable"
						? itemGender === "M"
							? " deshabilitado"
							: " deshabilitada"
						: actionCrud === "enable"
							? itemGender === "M"
								? " habilitado"
								: " habilitada"
							: itemGender === "M"
								? " guardado"
								: " guardada");
				response.messageSeverity = "success";
			} else {
				// si hay error controlado
				console.log(_errorMessage);
				console.log(response);

				let _message_code = response.data.message_code;
				let _message_description = response.data.message_description;
				if (_message_code === "err-fuero-subtipo-act-juzgado-already-exist") {
					response.messageText = `Ya existe ${elLa} ${nameItemText}. Verifique los inhabilitados`;
				} else if (_message_description.includes("[email] is not email")) {
					response.messageText = "El email no es válido";
				} else {
					response.messageText = _errorMessage;
				}
				response.ok = false;
				response.messageSeverity = "error";
			}
			return response;
		}
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	}
};
